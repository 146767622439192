class Dealer {
  constructor(dealer) {
    this.type = dealer.type;
    this.name = dealer.name;
    this.phone = dealer.phone;
    this.website = dealer.website;
    this.address = dealer.address;
    this.area = dealer.area;
    this.region = dealer.region;
    this.zip = dealer.zip;
    this.country = dealer.country;
    this.lat = dealer.location.lat;
    this.lng = dealer.location.lng;
  }
}

export default Dealer;
