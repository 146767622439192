import React, { Component } from 'react';

let Map, TileLayer, MarkerClusterGroup, Marker, marker, divIcon;

class StoreLocatorMap extends Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.onTileLoad = this.onTileLoad.bind(this);
    this.getMarkerIcon = this.getMarkerIcon.bind(this);
    this.setBounds = this.setBounds.bind(this);
  }

  componentDidMount() {
    Map = require('react-leaflet').Map;
    TileLayer = require('react-leaflet').TileLayer;
    MarkerClusterGroup = require('react-leaflet-markercluster').default;
    Marker = require('react-leaflet').Marker;
    marker = require('leaflet').marker;
    divIcon = require('leaflet').divIcon;

    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.regularMap !== this.props.regularMap) {
      return true;
    }
    return false;
  }

  getMarkerClusterIcon(cluster) {
    const clusterChildCount = cluster.getChildCount().toString();
    let markerClusterSize = 'medium';

    if (clusterChildCount.length === 1) {
      markerClusterSize = 'small';
    } else if (clusterChildCount.length === 2) {
      markerClusterSize = 'medium';
    } else {
      markerClusterSize = 'large';
    }

    return divIcon({
      html: `<div><span>${clusterChildCount}</span></div>`,
      className: `marker-cluster marker-cluster-${markerClusterSize}`,
    });
  }

  getMarkerIcon(dealerType) {
    return divIcon({
      html: `<svg
        class="${dealerType}"
        viewBox="0 0 10 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g fillRule="evenodd">
          <g transform="translate(-640.000000, -201.000000)">
            <path
              d="M645,215 C645,215 640,208.999505 640,206.153375 C640,203.307244 642.238576,201 645,201 C647.761424,201 650,203.307244 650,206.153375 C650,208.999505 645,215 645,215 Z M645,208 C646.104569,208 647,207.104569 647,206 C647,204.895431 646.104569,204 645,204 C643.895431,204 643,204.895431 643,206 C643,207.104569 643.895431,208 645,208 Z"
            />
          </g>
        </g>
      </svg>`,
    });
  }

  onTileLoad() {
    if (this.props.mapFirstLoad) {
      this.mapRef.current.leafletElement.on('locationfound', (location) => {
        this.props.mapLoadedWithLocation(
          this.mapRef.current.leafletElement,
          location
        );

        marker([location.latitude, location.longitude], {
          title: 'my-location',
          icon: this.getMarkerIcon(),
        }).addTo(this.mapRef.current.leafletElement);

        this.setBounds();
      });

      this.mapRef.current.leafletElement.on('locationerror', (error) => {
        this.props.mapLoadedWithoutLocation(this.mapRef.current.leafletElement);
      });
    }

    if (
      this.props.userLocation.lat === 0 &&
      this.props.userLocation.lng === 0
    ) {
      this.mapRef.current.leafletElement.locate();
    }
  }

  setBounds() {
    const dealerKeys = Object.keys(this.props.dealers);

    this.mapRef.current.leafletElement.fitBounds([
      [this.props.userLocation.lat, this.props.userLocation.lng],
      [
        this.props.dealers[this.props.closestDealer].lat,
        this.props.dealers[this.props.closestDealer].lng,
      ],
    ]);
  }

  render() {
    if (!Map || typeof window === 'undefined') return null;

    const dealerKeys = Object.keys(this.props.dealers);

    const markers = dealerKeys.map((dealerKey, index) => {
      const dealer = this.props.dealers[dealerKey];
      const marker = (
        <Marker
          key={index}
          icon={this.getMarkerIcon(dealer.type)}
          id={dealer.id}
          position={[dealer.lat, dealer.lng]}
          onClick={(e) => this.props.onMapMarkerClick(e, this.mapRef)}
        />
      );
      return marker;
    });

    return (
      <div className="store-locator__map">
        <Map
          onClick={(e) => this.props.setListActive(false)}
          onMoveStart={(e) => this.props.setListActive(false)}
          onViewportChanged={async (viewport) =>
            this.props.onMapViewportChanged(viewport, this.mapRef)
          }
          ref={this.mapRef}
          viewport={this.props.mapViewport}
        >
          <TileLayer
            attribution={`${
              !this.props.regularMap
                ? 'Maps &copy; <a href="http://www.thunderforest.com/" target="_blank" rel="noopener noreferrer">Thunderforest</a> | '
                : ''
            }&copy; <a href="http://osm.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> contributors`}
            url={`${
              this.props.regularMap
                ? 'http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                : 'https://tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png?apikey=e665117793db43b481d7013ee6fb3267'
            }`}
            onLoad={this.onTileLoad}
          />
          <MarkerClusterGroup
            animate={true}
            iconCreateFunction={this.getMarkerClusterIcon}
            showCoverageOnHover={false}
          >
            {markers}
          </MarkerClusterGroup>
        </Map>
      </div>
    );
  }
}

export default StoreLocatorMap;
