import Helmet from 'react-helmet';
import React, { Component } from 'react';
import { getDistance } from 'geolib';

import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import JsonLD from '../_common/JsonLD';
import Popup from '../_common/Popup';
import StoreLocatorList from './StoreLocatorList';
import StoreLocatorMap from './StoreLocatorMap';
import StoreRequest from './StoreRequest';
import { getCmsValue } from '../../utils/utils';
import { Ghost } from '../_common/Icons';

class Dealers extends Component {
  constructor() {
    super();

    this.state = {
      regularMap: true,
      dealers: {},
      visibleDealers: [],
      dealersAvailable: 0,
      userLocation: {
        lat: 0,
        lng: 0,
        data: null,
      },
      closestDealer: null,
      map: null,
      mapViewport: {
        center: [0, 0],
        zoom: 0,
      },
      mapFirstLoad: true,
      storeRequestOpen: false,
    };

    this.mapLoadedWithLocation = this.mapLoadedWithLocation.bind(this);
    this.mapLoadedWithoutLocation = this.mapLoadedWithoutLocation.bind(this);
    this.onMapViewportChanged = this.onMapViewportChanged.bind(this);
    this.onMapMarkerClick = this.onMapMarkerClick.bind(this);
    this.setListRefs = this.setListRefs.bind(this);
    this.setListActive = this.setListActive.bind(this);
    this.setStoreRequestOpen = this.setStoreRequestOpen.bind(this);

    this.listRef;
    this.listItemRefs = {};
  }

  componentDidMount() {
    this.setDealers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dealers.length !== this.state.dealers.length ||
      prevState.userLocation.lat !== this.state.userLocation.lat
    ) {
      this.setDealers();
    }
  }

  setDealers() {
    const dealers = this.props.dealers.map((dealer) => {
      const myLocation = {
        latitude: this.state.userLocation.lat,
        longitude: this.state.userLocation.lng,
      };
      const dealerLocation = {
        latitude: parseInt(dealer.lat),
        longitude: parseInt(dealer.lng),
      };

      return {
        ...dealer,
        distance: getDistance(myLocation, dealerLocation),
      };
    });

    const dealersObj = dealers.reduce((acc, dealer, index) => {
      return {
        ...acc,
        [index]: {
          ...dealer,
          id: index,
        },
      };
    }, {});

    this.setState({
      ...this.state,
      dealers: dealersObj,
    });
  }

  mapLoadedWithLocation(map, location) {
    const closestDealer = Object.entries(this.state.dealers)
      .map(([k, v]) => v)
      .sort((a, b) => (a.distance < b.distance ? -1 : 1))[0].id;

    this.setState({
      ...this.state,
      mapFirstLoad: false,
      map: map,
      closestDealer: closestDealer,
      userLocation: {
        lat: location.latitude,
        lng: location.longitude,
      },
    });

    map.invalidateSize();
    this.setCity();
  }

  mapLoadedWithoutLocation(map) {
    this.setState({
      ...this.state,
      mapFirstLoad: false,
      map: map,
    });

    map.invalidateSize();
  }

  async setCity() {
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${this.state.userLocation.lat}&longitude=${this.state.userLocation.lng}&localityLanguage=en`
    )
      .then((res) => res.json())
      .then((res) =>
        this.setState({
          ...this.state,
          userLocation: {
            ...this.state.userLocation,
            data: res,
          },
        })
      );
  }

  async onMapViewportChanged(viewport, mapRef) {
    this.setDealersAvailable(mapRef.current.leafletElement);

    if (viewport.zoom >= 7 || this.state.dealersAvailable === 1) {
      this.setVisibleDealers(mapRef.current.leafletElement);
    } else {
      this.setState({
        ...this.state,
        visibleDealers: [],
      });
    }

    this.setState({
      ...this.state,
      mapViewport: viewport,
      activeDealerId: null,
    });
  }

  async setDealersAvailable(map) {
    let dealersAvailable = 0;

    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (
          map.getBounds().contains(layer.getLatLng()) &&
          layer.options.title !== 'my-location'
        ) {
          dealersAvailable += 1;
        }
      }
    });

    this.setState({
      ...this.state,
      dealersAvailable: dealersAvailable,
    });
  }

  async setVisibleDealers(map) {
    let dealerMarkers = [];

    const getClusterMarkers = (markers, cluster) => {
      if (cluster._markers.length > 0) {
        markers = markers.concat(cluster._markers);
      }
      if (cluster._childClusters.length > 0) {
        cluster._childClusters.forEach((childCluster) => {
          markers = getClusterMarkers(markers, childCluster);
        });
      }

      return markers;
    };

    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (
          map.getBounds().contains(layer.getLatLng()) &&
          layer.options.title !== 'my-location'
        ) {
          if (typeof layer.options.id !== 'undefined') {
            dealerMarkers.push(layer);
          } else if (layer._markers || layer._childClusters) {
            dealerMarkers = getClusterMarkers(dealerMarkers, layer);
          }
        }
      }
    });

    const visibleDealers = [
      ...new Set(
        dealerMarkers.map((marker) => {
          return marker.options.id;
        })
      ),
    ].map((id) => this.state.dealers[id]);

    this.setState({
      ...this.state,
      visibleDealers: visibleDealers,
    });
  }

  onMapMarkerClick(e, mapRef) {
    const dealerId = e.target.options.id;

    if (this.state.visibleDealers.length === 0) {
      const dealer = this.state.dealers[dealerId];
      mapRef.current.leafletElement.flyToBounds([[dealer.lat, dealer.lng]], {
        maxZoom: 7,
      });
    }

    this.setState({
      ...this.state,
      activeDealerId: dealerId,
      listActive: true,
    });

    try {
      this.listRef.current.scrollTo({
        left: 0,
        top: this.listItemRefs[dealerId].offsetTop,
        behavior: 'smooth',
      });
    } catch (err) {}
  }

  setListRefs(listRef, listItemRefs) {
    this.listRef = listRef;
    this.listItemRefs = listItemRefs;
  }

  setListActive(active) {
    this.setState({
      ...this.state,
      listActive: active,
    });
  }

  setStoreRequestOpen() {
    this.setState((prevState) => ({
      ...prevState,
      storeRequestOpen: !this.state.storeRequestOpen,
    }));
  }

  render() {
    const { jsonLd, site } = this.props;

    return (
      <div className="store-locator">
        <Helmet
          title={getCmsValue(
            site.strings.storeLocator__storeLocatorTitle,
            site
          )}
        />
        <JsonLD jsonLd={jsonLd} />

        {Object.keys(this.state.dealers).length > 0 && (
          <>
            {/*<button
              className="store-locator__ghost"
              onClick={(e) =>
                this.setState((prevState) => ({
                  ...prevState,
                  regularMap: !this.state.regularMap,
                }))
              }
            >
              {Ghost}
            </button>*/}
            <StoreLocatorList
              activeDealerId={this.state.activeDealerId}
              dealersAvailable={this.state.dealersAvailable}
              listActive={this.state.listActive}
              map={this.state.map}
              setListActive={this.state.setListActive}
              setListRefs={this.setListRefs}
              setStoreRequestOpen={this.setStoreRequestOpen}
              site={site}
              userLocation={this.state.userLocation}
              visibleDealers={this.state.visibleDealers}
            />
            <StoreLocatorMap
              closestDealer={this.state.closestDealer}
              dealers={this.state.dealers}
              mapFirstLoad={this.state.mapFirstLoad}
              mapLoadedWithLocation={this.mapLoadedWithLocation}
              mapLoadedWithoutLocation={this.mapLoadedWithoutLocation}
              mapViewport={this.state.mapViewport}
              onMapMarkerClick={this.onMapMarkerClick}
              onMapViewportChanged={this.onMapViewportChanged}
              regularMap={this.state.regularMap}
              setListActive={this.setListActive}
              site={site}
              userLocation={this.state.userLocation}
            />
            <Popup
              onClose={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  storeRequestOpen: false,
                }))
              }
              visible={this.state.storeRequestOpen}
            >
              <StoreRequest />
            </Popup>
          </>
        )}
      </div>
    );
  }
}

export default Dealers;
