import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import React, { Component } from 'react';
import Select from '../_common/Select';

import countries from './countries.json';

class StoreRequest extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      address: {
        country: 'US',
        name: '',
        phone: '',
        website: '',
        address: '',
        city: '',
        state: '',
        zip: '',
      },
      loading: false,
      message: null,
    };
    this.state = { ...this.initialState };
  }

  onChange(e) {
    this.setState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [e.target.name]: e.target.value,
      },
      loading: false,
      message: null,
    }));
  }

  onSelectChange(selected, key) {
    this.setState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [key]: selected.value,
      },
    }));
  }

  async onSubmit(e) {
    e.preventDefault();

    const { address } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await axios({
      method: 'POST',
      url: `/api/dealer/create`,
      data: address,
    })
      .then((res) => res.status)
      .catch(() => 400);

    const setMessage = (message) => {
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
        message: message,
      }));
    };

    if (response === 201) {
      setMessage('Sent for approval');
    } else if (response === 204) {
      setMessage('Already requested');
    } else if (response === 400) {
      setMessage('Something went wrong');
    } else {
      setMessage('Something went wrong');
    }
  }

  render() {
    const { address } = this.state;

    const selectCountries = Object.keys(countries).map((countryCode) => ({
      label: countries[countryCode].name,
      value: countryCode,
    }));
    const selectedCountry = Object.keys(countries).find(
      (countryCode) => countryCode === address.country
    );

    const selectStates = Object.keys(
      countries[selectedCountry]?.states || []
    ).map((stateCode) => ({
      label: countries[selectedCountry]?.states[stateCode],
      value: countries[selectedCountry]?.states[stateCode],
    }));
    const selectedState = Object.values(
      countries[selectedCountry]?.states || []
    ).find((stateName) => stateName === address.state);

    return (
      <form
        className="store-locator__request"
        onSubmit={this.onSubmit.bind(this)}
      >
        <div className="country">
          <label htmlFor="country">Country</label>
          <Select
            name="country"
            options={selectCountries}
            value={{
              value: selectedCountry,
              label: countries[selectedCountry].name,
            }}
            onChange={(e) => this.onSelectChange.bind(this)(e, 'country')}
            required
          />
        </div>
        <div className="name">
          <label htmlFor="name">Name</label>
          <input
            name="name"
            value={address.name}
            onChange={this.onChange.bind(this)}
            type="text"
            required
          />
        </div>
        <div className="phone">
          <label htmlFor="phone">Phone</label>
          <PhoneInput
            value={address.phone}
            onChange={(e) =>
              this.onChange.bind(this)({
                target: {
                  name: 'phone',
                  value: e,
                },
              })
            }
            defaultCountry={address.country}
          />
        </div>
        <div className="website">
          <label htmlFor="website">Website</label>
          <input
            name="website"
            value={address.website}
            onChange={this.onChange.bind(this)}
            type="url"
            required
          />
        </div>
        <div className="address">
          <label htmlFor="address">Address</label>
          <input
            name="address"
            value={address.address}
            onChange={this.onChange.bind(this)}
            type="text"
            required
          />
        </div>
        <div className="city">
          <label htmlFor="city">City</label>
          <input
            name="city"
            value={address.city}
            onChange={this.onChange.bind(this)}
            type="text"
            required
          />
        </div>
        {selectStates.length > 0 && (
          <div className="state">
            <label htmlFor="state">State</label>
            <Select
              name="state"
              options={selectStates}
              value={{
                value: selectedState,
                label: selectedState,
              }}
              onChange={(e) => this.onSelectChange.bind(this)(e, 'state')}
            />
          </div>
        )}
        <div className="zip">
          <label htmlFor="zip">Zip</label>
          <input
            name="zip"
            value={address.zip}
            onChange={this.onChange.bind(this)}
            type="text"
            required
          />
        </div>
        <input
          className={`submit-button ${
            this.state.loading ? 'submit-button--loading' : ''
          }`}
          type="submit"
          disabled={this.state.message || this.state.loading}
        />
        <span className="message">{this.state.message || ''}</span>
      </form>
    );
  }
}

export default StoreRequest;
