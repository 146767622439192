import React, { Component } from 'react';
import { graphql } from 'gatsby';

import breadcrumbs from './breadcrumbs';
import DealerModel from '../models/Dealer';
import StoreLocator from '../components/store-locator/StoreLocator';
import { mapEdgesToNodes } from '../utils/utils';

export const query = graphql`
  query {
    allSanityDealer {
      edges {
        node {
          address
          area
          country
          location {
            lat
            lng
          }
          name
          phone
          region
          type
          website
          zip
        }
      }
    }
  }
`;

class Stores extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { site } = this.props;
    const { allSanityDealer } = this.props.data;

    const dealers = mapEdgesToNodes(allSanityDealer).map(
      (dealer) => new DealerModel(dealer)
    );

    return (
      <StoreLocator
        breadcrumbs={breadcrumbs('STORES', '')}
        dealers={dealers}
        site={site}
      />
    );
  }
}

export default Stores;
